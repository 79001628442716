@import "reset.scss";


.form-white {
    .MuiOutlinedInput-notchedOutline {
        border-color: #fff !important;
    }

    .MuiInputBase-input,
    .MuiFormLabel-root {
        color: #fff !important;
    }

    .MuiInputAdornment-root {
        p {
            color: #fff;
        }
    }

    .inactive {
        .MuiInputBase-input,
        .MuiFormLabel-root {
            -webkit-text-fill-color: #ffffff7e !important;
        }
    }

    .buttonDisabled {
        color:darkslategray !important;
        background-color: beige !important;
    }
}