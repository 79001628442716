*,
html,
body,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body p,
body ul,
body li {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    font-family: 'Jost', sans-serif;
}

body #root {
    font-size: 16px;
    font-family: 'Jost', sans-serif;
}

::-moz-selection {
    /* Code for Firefox */
    color: #fff !important;
    background: #5168f4 !important;
}

::selection {
    color: #fff !important;
    background: #5168f4 !important;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f0f0f0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}


body.this-is-email-data-page {
    // min-height: 100vh;
    // display: flex;
    // flex-direction: column;
    padding: 0 !important;
    line-height: unset !important;

    h1 {
        max-width: unset !important;
    }
}

body label {
    margin: 0;
}

footer {
    // margin-top: auto;
}

i.fa {
    font-size: inherit;
}

body ul,
body ol {
    list-style: none;
}

img {
    max-width: 100%;
}

.email-view-body_text img,
.event-notes-box h6.max-wdith-responsive img {
    max-width: unset;
}

body a {
    text-decoration: none !important;
    color: #5168f4;
    cursor: pointer;
}

body a:hover,
body a:focus,
body a:active {
    text-decoration: none !important;
    outline: none;
}

body address {
    font-style: normal;
}

body textarea {
    resize: none;
}

body textarea:hover,
body textarea:focus,
body textarea:active {
    outline: none;
    box-shadow: none;
}

body input:hover,
body input:active,
body input:focus {
    outline: none;
    box-shadow: none;
}

body select:hover,
body select:active,
body select:focus {
    outline: none;
    box-shadow: none;
}

body input[type=number] {
    -moz-appearance: textfield;
    /*For FireFox*/
}

body input[type=number]::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
}

body button,
body button:hover,
body button:focus,
body button:active {
    outline: none;
    box-shadow: none;
    cursor: pointer;
}

body img {
    border: none;
}

body a img {
    border: 0px;
}
body{
    .form-control:disabled {
        background-color: #f2f2f2;
    }
}
body figure {
    margin: 0;
    padding: 0;
}

body .slick-initialized .slick-slide {
    outline: none;
    border: none;
}

div#root~iframe {
    display: none !important;
}
.button-div-style{
    background-color: transparent;
    text-align: left;
    display: block;
}